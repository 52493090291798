<template>
  <v-app id="register">
    <!-- <v-main> -->
    <!-- <header-register /> -->
    <v-container fluid>
      <div class="main-container">
        <div class="py-12">
          <v-row>
            <v-col
              class="txt-sub-6"
              cols="12"
            >
              Asal Keanggotaan
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1">
              Silahkan lengkapi beberapa data diri di bawah
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Asal BPD (Provinsi) <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-custom-select
                v-model="selectedPropinsi"
                :options="propinsi"
                :clearable="false"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Asal BPC (Kab/Kota) <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-custom-select
                v-model="selectedKabupatenKota"
                :options="kabupatenKota"
                :clearable="false"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Jenis Keanggotaan <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              class="d-flex mt-n6"
              style="flex-direction: column"
            >
              <v-radio-group v-model="jenisKeanggotaan">
                <v-radio
                  value="profesional"
                  color="#38BA98"
                >
                  <template v-slot:label>
                    <span class="txt-sub-1">
                      Profesional
                      <span class="txt-sub-3"> (Untuk para pengusaha muda) </span>
                    </span>
                  </template>
                </v-radio>

                <v-radio
                  value="universitas"
                  color="#38BA98"
                >
                  <template v-slot:label>
                    <span class="txt-sub-1">
                      Universitas
                      <span class="txt-sub-3">
                        (Untuk para pengusaha muda tingkat Universitas)
                      </span>
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Tahu HIPMI dari?
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-textarea
                v-model="sumberInfo"
                rows="4"
                no-resize
                outlined
                placeholder="Masukkan Penjelasan"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Siapa yang mereferensikan?
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="referensi"
                name="referensi"
                type="text"
                label="Masukkan Nama Lengkap"
                outlined
                autocomplete="off"
                dense
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="text-capitalize"
                color="#36AC87"
                block
                x-large
                :disabled="disableButton"
                small
                @click="submit"
              >
                <span class="txt-btn-login"> Lanjutkan Pendaftaran </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <!-- </v-main> -->
  </v-app>
</template>

<script>
  import axios from 'axios'
  // import RegistrasiHeader from '../registrasi/RegistrasiHeader.vue'
  // import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      // 'header-register': RegistrasiHeader,
    },

    data: () => ({
      // Form Data:
      selectedPropinsi: [
        {
          label: 'Pilih Propinsi',
        },
      ],
      propinsi: [],

      selectedKabupatenKota: [
        {
          label: 'Pilih Kabupaten/ Kota',
        },
      ],
      kabupatenKota: [],

      sumberInfo: '',

      referensi: '',

      jenisKeanggotaan: 'profesional',

      disableButton: false,

      // Permission:
      // userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
    },

    watch: {
    },

    created () {
      // this.initialize()
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit () {
        // console.log(this.jenisKeanggotaan)
        switch (this.jenisKeanggotaan) {
          case 'profesional':
            this.$router.push({ name: 'RegisterProfesionalDataDiri' })
            break

          case 'universitas':
            this.$router.push({ name: 'RegisterUniversitas' })
            break
        }
      },

      navBack () {
        this.$router.push({ name: 'Mitra' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txt-login {
    @extend .h-4;
    color: $gray-50;
    font-weight: bold;
  }

  .txt-sub-1 {
    @extend .p-1;
    color: $gray-50;
  }

  .txt-sub-2 {
    @extend .p-1;
    color: $gray-50;
    font-style: italic;
  }

  .txt-sub-3 {
    @extend .p-2;
    color: $gray-50;
  }

  .txt-sub-4 {
    @extend .p-2;
    color: $blue-1;
    cursor: pointer;
    font-weight: bold;
  }

  .txt-sub-5 {
    @extend .p-2;
    color: $gray-80;
  }

  .txt-sub-6 {
    @extend .h-5;
    color: $black-1;
    font-weight: bold;
  }

  .txt-btn-login {
    @extend .h-5;
    color: #FFFFFF;
    font-weight: bold;
  }

  .btn-login {
    color: $green-2;
  }

  .symbol-required {
    color: $red-1;
  }

  .dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
  }

  .txt-12-green-2-bold {
    @extend .p-2;
    color: $green-2;
    font-weight: bold;
  }
</style>
